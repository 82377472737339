export const API_URL: 'https://app.channex.io/api/v1/meta' =
	'https://app.channex.io/api/v1/meta'
export const API_BOOKING_URL: 'https://secure.channex.io/api/v1/meta' =
	'https://secure.channex.io/api/v1/meta'
export const CHANNEL_NAME: 'ACEBookingEngine' = 'ACEBookingEngine'

export const PROPERTY_ID: "a02ea2b1-9b60-492a-86e4-2f4dc0f54559" =
  "a02ea2b1-9b60-492a-86e4-2f4dc0f54559";

export const API_KEY: 'gEwDfL13CFMTBUEzOLKIVrGBwYVcsOuC3oQXKTYq1epBs+9sKt+/VjhpFUKv4ar1' =
	'gEwDfL13CFMTBUEzOLKIVrGBwYVcsOuC3oQXKTYq1epBs+9sKt+/VjhpFUKv4ar1'

export const API2_URL: 'https://bookingadmin.acemsthailand.com/api' =
	'https://bookingadmin.acemsthailand.com/api';

export const FEET_IN_METER: 3.28 = 3.28;

export const STRIPE_PUBLICK_KEY: 'pk_test_51QSGNRPhWbFDBWZsTnuSOyW8iEvgngisNGeKulJ7buUdy4GjjfT7CcARYNBvJTtqNQPaC92i8bzyrUgrIQHYsv0w00VSyvtwsP' =
	'pk_test_51QSGNRPhWbFDBWZsTnuSOyW8iEvgngisNGeKulJ7buUdy4GjjfT7CcARYNBvJTtqNQPaC92i8bzyrUgrIQHYsv0w00VSyvtwsP';

export const SPARKPOST_API_KEY: '81078c1f3a0900a88cc10c18e526b0f2cc830549' =
	'81078c1f3a0900a88cc10c18e526b0f2cc830549';

export const API2_OPSERV: 'https://bookingadmin.acemsthailand.com/api' =
	'https://bookingadmin.acemsthailand.com/api'