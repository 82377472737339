import { AppDispatch } from '../../utils/types'
import { apiRequest } from '../../utils/api'
import {
	API2_OPSERV,
	API2_URL,
	API_KEY,
	STRIPE_PUBLICK_KEY,
} from '../../utils/constants'
import { Stripe, loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(STRIPE_PUBLICK_KEY);

export const SET_STRIPE_CHECKOUT_SESSION_REQUEST: 'SET_STRIPE_CHECKOUT_SESSION_REQUEST' =
	'SET_STRIPE_CHECKOUT_SESSION_REQUEST';
export const SET_STRIPE_CHECKOUT_SESSION_SUCCESS: 'SET_STRIPE_CHECKOUT_SESSION_SUCCESS' =
	'SET_STRIPE_CHECKOUT_SESSION_SUCCESS'
export const SET_STRIPE_CHECKOUT_SESSION_FILED: 'SET_STRIPE_CHECKOUT_SESSION_FILED' =
	'SET_STRIPE_CHECKOUT_SESSION_FILED';

export const SET_STRIPE_CHECKOUT_REQUEST: 'SET_STRIPE_CHECKOUT_REQUEST' =
	'SET_STRIPE_CHECKOUT_REQUEST';
export const SET_STRIPE_CHECKOUT_SUCCESS: 'SET_STRIPE_CHECKOUT_SUCCESS' =
	'SET_STRIPE_CHECKOUT_SUCCESS'
export const SET_STRIPE_CHECKOUT_FILED: 'SET_STRIPE_CHECKOUT_FILED' =
	'SET_STRIPE_CHECKOUT_FILED';

const currentUrl = new URL(window.location.href)
const origin = currentUrl.origin
const pathSegments = currentUrl.pathname
	.split('/')
	.filter(segment => segment.length > 0)

const baseUrl = `${origin}/${pathSegments[0]}`

const successUrl = `${baseUrl}/?status=success`
const cancelUrl = `${baseUrl}/?status=cancel`

export const setStripeCheckout =
	(sessionId: string) => (dispatch: AppDispatch) => {
		dispatch({ type: SET_STRIPE_CHECKOUT_REQUEST })
		return stripePromise
			.then(stripe => {
				if (!stripe) {
					throw new Error('Stripe.js failed to load.')
				}
				return stripe.redirectToCheckout({ sessionId })
			})
			.then(result => {
				if (result.error) {
					// Обработка ошибки, если она есть
					throw new Error(result.error.message)
				}
				// Всё прошло успешно
			})
			.catch(error => {
				dispatch({ type: SET_STRIPE_CHECKOUT_FILED })
				console.error('Failed to create checkout:', error)
				throw error
				// setPaymentError('Failed to create checkout session')
			})
			.finally(() => {
				// setLoading(false)
			})
	}

export const setStripeCheckoutSession =
	(email: string | undefined) => (dispatch: AppDispatch) => {
		dispatch({ type: SET_STRIPE_CHECKOUT_SESSION_REQUEST })
		return apiRequest(`${API2_URL}/payment/checkout`, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'api-key': API_KEY,
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
			body: JSON.stringify({
				email,
				success_url: successUrl,
				cancel_url: cancelUrl,
			}),
		})
			.then(response => {
				console.log('responseKK', response)
				const sessionId: string = response.id
				dispatch({ type: SET_STRIPE_CHECKOUT_SESSION_SUCCESS, sessionId })
				return dispatch(setStripeCheckout(sessionId))
			})
			.catch(error => {
				dispatch({ type: SET_STRIPE_CHECKOUT_SESSION_FILED })
				console.error('Failed to create checkout session:', error)
				throw error
				// setPaymentError('Failed to create checkout session')
			})
			.finally(() => {
				// setLoading(false)
			})

	}