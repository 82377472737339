import React, { useEffect, useState } from "react";
import MainMenu from "../../components/MainMenu/MainMenu";
import BookNow from "../../components/BookNow/BookNow";

import styles from "./main-page.module.scss";
import { useWindowWidth } from "../../utils/helpers";
import Loader from "../../ui/Loader/Loader";
import { useAppSelector } from "../../hooks/redux";
import { useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import Modal from '../../components/Modal/Modal';

const MainPage = () => {
  const width = useWindowWidth();
  const location = useLocation();
  const { request, failed } = useAppSelector((store) => store.property);
  const [visible, setVisible] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null)
  const { hotel } = useAppSelector((store) => store.hotelBySlug);

  useEffect(() => {
    width && setIsVisible(width < 768);
  }, [width]);

  useEffect(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  const handleBookNowShow = () => {
    setVisible(true);
  };

  const handleBookNowHide = () => {
    setVisible(false);
  };

  const handleRemoveStatus = () => {
		const currentUrl = window.location.href
		const url = new URL(currentUrl)
		url.searchParams.delete('status')
		window.history.pushState({}, '', url.toString())
	}

  const handleCloseInfoStatus = () => {
    setStatus(null)
    handleRemoveStatus()
  };

  useEffect(() => {
		const query = new URLSearchParams(location.search)
		const status = query.get('status')
		if (status) {
			setStatus(status)
		}
	}, [location])

  return (
		<main
			className={styles.container}
			style={{
				background:
					hotel?.background_image !== '#ffffff'
						? `url('${hotel?.background_image}')`
						: '',
				backgroundSize: 'cover',
				minHeight: '100vh',
				width: '100%',
			}}
		>
			<MainMenu />
			<div
				className={`${styles.content} ${
					visible && isVisible ? styles.full : ''
				}`}
				style={
					visible && isVisible
						? {
								background:
									hotel?.primary_color !== '#ffffff'
										? hotel?.primary_color
										: '',
						  }
						: {}
				}
			>
				{request || failed ? (
					<Loader request={request} />
				) : (
					<>
						{isVisible && !visible && (
							<button
								className={styles.book}
								onClick={handleBookNowShow}
								style={{
									background:
										hotel?.secondary_color !== '#ffffff'
											? hotel?.secondary_color
											: '',
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								Book now
							</button>
						)}
						{visible && isVisible && (
							<button
								className={styles.close}
								onClick={handleBookNowHide}
								style={{
									color:
										hotel?.third_color !== '#ffffff' ? hotel?.third_color : '',
								}}
							>
								Close X
							</button>
						)}
						{visible && <BookNow />}
					</>
				)}
			</div>
			<AnimatePresence>
				{status === 'success' && (
					<Modal onClose={handleCloseInfoStatus} modalStyle={styles.termModal}>
						<p>Thank you for issuing the payment. You will shortly receive email confirmation of your booking</p>
					</Modal>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{status === 'cancel' && (
					<Modal onClose={handleCloseInfoStatus} modalStyle={styles.termModal}>
						<p>You have cancelled the payment process.</p>
					</Modal>
				)}
			</AnimatePresence>
		</main>
	)
};

export default MainPage;
